<template>
    <div class="notfound">
        <div class="notfound-404">
            <h3></h3>
            <h1><span>4</span><span>0</span><span>4</span></h1>
        </div>
        <h2>{{ $t('error.notFound') }}</h2>
        <small>v{{ appVersion }}</small>
    </div>
</template>

<script>
import { version } from '../../../../../package';
export default {
    data: () => ({
        appVersion: version
    })
};
</script>
